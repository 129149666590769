<template>
	<svg class="logo" width="3646" height="425" viewBox="0 0 3646 425" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 299.874V131.853L285.364 260.084V424.534L0 299.874Z" fill="white" />
		<path d="M314.641 260.087L600.005 131.853V299.877L314.641 424.534V260.087Z" fill="white" />
		<path d="M314.641 164.746V0.14502L585.371 113.761L396.216 197.705L314.641 164.746Z" fill="white" />
		<path d="M21.9609 113.761L285.374 0.14502V164.746L211.127 197.705L21.9609 113.761Z" fill="white" />
		<path d="M995.765 196.818C1020.29 214.506 1037.98 240.234 1037.98 270.786C1037.98 317.016 1010.24 354 946.319 354H689.039V72.6001H934.259C998.177 72.6001 1017.88 101.544 1017.88 144.156C1017.88 162.246 1009.43 180.738 995.765 196.818ZM793.559 140.94V171.492H890.039C900.491 171.492 913.355 171.492 913.355 156.216C913.355 140.94 900.491 140.94 890.039 140.94H793.559ZM910.139 277.62C920.591 277.62 933.455 277.62 933.455 262.746C933.455 247.872 920.591 247.872 910.139 247.872H793.559V277.62H910.139Z" fill="white" />
		<path d="M1154.73 72.6001V354H1050.21V72.6001H1154.73Z" fill="white" />
		<path d="M1553.44 72.6001L1391.43 205.26L1553.44 354H1408.72L1318.67 264.756L1275.25 300.534V354H1170.73V72.6001H1275.25V182.748L1404.7 72.6001H1553.44Z" fill="white" />
		<path d="M1859.22 153H1666.26V179.13H1859.22V247.47H1666.26V273.6H1859.22V354H1561.74V72.6001H1859.22V153Z" fill="white" />
		<path d="M1925.27 257.118H1997.63C1997.63 286.866 2021.75 300.534 2113.4 300.534C2189.38 300.534 2212.29 292.092 2212.29 269.982C2212.29 246.666 2196.21 242.646 2104.96 238.224C1982.35 232.596 1933.71 213.3 1933.71 150.186C1933.71 88.2781 1996.82 68.5801 2102.15 68.5801C2208.27 68.5801 2272.59 95.1121 2272.59 164.658H2200.23C2200.23 133.704 2169.28 126.066 2094.11 126.066C2022.95 126.066 2006.07 132.9 2006.07 153.804C2006.07 175.512 2022.95 179.532 2104.96 184.356C2215.91 190.386 2284.65 196.416 2284.65 267.57C2284.65 342.744 2213.5 358.02 2109.38 358.02C1994.01 358.02 1925.27 338.322 1925.27 257.118Z" fill="white" />
		<path d="M2531.97 72.6001C2595.89 72.6001 2639.71 113.202 2639.71 175.914C2639.71 238.626 2595.89 278.826 2531.97 278.826H2383.23V354H2310.87V72.6001H2531.97ZM2511.87 218.124C2533.58 218.124 2567.35 218.124 2567.35 175.914C2567.35 133.302 2533.58 133.302 2511.87 133.302H2383.23V218.124H2511.87Z" fill="white" />
		<path d="M2908.44 354L2878.29 294.102H2709.85L2679.7 354H2600.11L2745.23 72.6001H2842.92L2988.44 354H2908.44ZM2736.79 240.234H2851.36L2794.27 126.066L2736.79 240.234Z" fill="white" />
		<path d="M2969.52 212.898C2969.52 119.634 3022.59 68.5801 3146.4 68.5801C3258.56 68.5801 3314.84 111.192 3322.08 192.396H3248.51C3241.68 146.97 3206.7 129.282 3146.4 129.282C3072.84 129.282 3041.88 155.412 3041.88 212.898C3041.88 270.786 3073.24 297.318 3146.4 297.318C3206.3 297.318 3241.68 279.228 3248.51 233.4H3322.08C3314.84 315.006 3258.56 358.02 3146.4 358.02C3022.59 358.02 2969.52 306.564 2969.52 212.898Z" fill="white" />
		<path d="M3645.94 130.086H3420.82V187.17H3645.94V239.43H3420.82V296.514H3645.94V354H3348.46V72.6001H3645.94V130.086Z" fill="white" />
	</svg>
</template>
